/* eslint-disable no-nested-ternary */
import { useQuery } from '@apollo/client';
import { CircularProgress, Hidden } from '@mui/material';
import {
  AdminDevicesQuery,
  AdminDevicesQueryVariables,
  DeviceSearchSortColumn,
  OrderEnum,
} from '__generated__/graphql';
import type { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import { Fragment, useCallback, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import DevicesListFilters from '../DevicesListPage/PageViews/DevicesListFilters';
import { ADMIN_DEVICE_LIST } from './AdminDeviceListPage.gql';
import { DeviceListMobileView, DeviceListView } from './PageViews';
type Props = {
  selectedOrg: SelectedOrgType;
  selectedLocation: SelectedLocationType;
};

const PAGE_SIZE = 15;

export default function AdminDevicesListPage({ selectedOrg }: Props) {
  const { showBoundary: showErrorBoundary } = useErrorBoundary();

  // Don't show spinner when loading next page
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [allDevices, setAllDevices] =
    useState<NonNullable<AdminDevicesQuery['deviceSearch']>['devices']>();

  const {
    data: devicesListData,
    error: queryError,
    loading: isLoading,
    refetch,
    variables: deviceListVariables,
  } = useQuery<AdminDevicesQuery, AdminDevicesQueryVariables>(ADMIN_DEVICE_LIST, {
    variables: {
      deviceSearchOptions: {},
      pagination: {
        limit: PAGE_SIZE,
        skip: 0,
      },
      sort: [
        {
          column: DeviceSearchSortColumn.ConnectivityDate,
          order: OrderEnum.Desc,
        },
      ],
    },
    onCompleted: (devices) => {
      if (!allDevices || allDevices.length === 0) {
        setAllDevices(devices.deviceSearch?.devices);
      }
    },
  });

  if (queryError) {
    showErrorBoundary(queryError);
  }

  const loadNextPage = useCallback(() => {
    if (!allDevices || allDevices.length === 0) {
      return;
    }
    refetch({
      ...deviceListVariables,
      pagination: {
        limit: PAGE_SIZE,
        skip: (deviceListVariables?.pagination?.skip ?? 0) + PAGE_SIZE,
      },
    }).then((devices) => {
      const newDevices = devices.data?.deviceSearch?.devices;
      setAllDevices((prev) => {
        if (prev && newDevices) {
          return [...prev, ...newDevices];
        }
        return prev;
      });
    });
    // eslint-disable-next-line
  }, [deviceListVariables, allDevices]);

  const filterHandler = useCallback(
    (variables: typeof deviceListVariables) => {
      const refetchArgs = {
        ...deviceListVariables,
        ...variables,
        deviceSearchOptions: {
          ...deviceListVariables?.deviceSearchOptions,
          ...variables?.deviceSearchOptions,
        },
        pagination: {
          limit: PAGE_SIZE,
          skip: 0,
        },
      };
      setLoadingFilter(true);
      refetch(refetchArgs)
        .then((res) => setAllDevices(res.data?.deviceSearch?.devices))
        .finally(() => setLoadingFilter(false));
    },
    // eslint-disable-next-line
    [deviceListVariables]
  );

  const postRegisterDevice = useCallback(
    (
      serialNumber: string,
      org: Record<'id' | 'name', string>,
      location: Record<'id' | 'name', string>
    ) => {
      setAllDevices((prev) => {
        if (!prev) return prev;
        return prev.map((device) => {
          if (device?.serialNumber !== serialNumber) return device;
          return {
            ...device,
            account: {
              __typename: 'Account' as const,
              id: org.id,
              name: org.name,
            },
            fullLocationPath: [
              {
                __typename: 'Location' as const,
                id: location.id,
                name: location.name,
              },
            ],
          };
        });
      });
    },
    []
  );
  return (
    <PageContainerFrame pageTitles={['Admin', 'Devices']}>
      <SettingsPanelFrame title='Devices'>
        <DevicesListFilters filterHandler={filterHandler} />
        {isLoading || loadingFilter ? (
          <CircularProgress sx={{ alignSelf: 'center', marginTop: 10 }} size={100} />
        ) : (
          <Fragment>
            <Hidden lgUp>
              <DeviceListMobileView
                devices={allDevices}
                totalCount={devicesListData?.deviceSearch?.totalCount ?? 0}
                selectedOrg={selectedOrg}
                loadNextPage={loadNextPage}
                postRegisterDevice={postRegisterDevice}
              />
            </Hidden>
            <Hidden lgDown>
              <DeviceListView
                devices={allDevices}
                totalCount={devicesListData?.deviceSearch?.totalCount ?? 0}
                selectedOrg={selectedOrg}
                loadNextPage={loadNextPage}
                postRegisterDevice={postRegisterDevice}
              />
            </Hidden>
          </Fragment>
        )}
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
