/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
import { gql, useQuery } from '@apollo/client';
import {
  Check as CheckIcon,
  ContentCopy as CopyIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DeviceAir20, DeviceQuery, DeviceQueryVariables, DeviceType } from '__generated__/graphql';
import type { SelectedOrgType } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import PollingSpeedDial from 'Components/SharedUI/PollingSpeedDial';
import RoutePaths from 'Constants/RoutePaths';
import useCopy from 'Hooks/useCopy';
import usePolling, { POLL_STATES } from 'Hooks/usePolling';
import moment from 'moment';
import { ModalNotificationTypeEnum, useModal } from 'Providers/ModalProvider';
import { useCallback, useMemo, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate, useParams } from 'react-router-dom';
import DeviceDetailsView from './PageViews/DeviceDetailsView';

export const GET_DEVICE_DETAILS = gql`
  query device(
    $accountId: ID!
    $serialNumber: String!
    $includeCleaningCycles: Boolean = false
    $cleaningCyclesStartDate: Date!
    $cleaningCyclesEndDate: Date!
    $includeMotionEvents: Boolean = false
    $motionEventsStartDate: Date!
    $motionEventsEndDate: Date!
    $includeInfoEvents: Boolean = false
    $infoEventsStartDate: Date!
    $infoEventsEndDate: Date!
    $includeOtaEvents: Boolean = false
    $otaEventsStartDate: Date!
    $otaEventsEndDate: Date!
    $includeErrorEvents: Boolean = false
    $errorEventsStartDate: Date!
    $errorEventsEndDate: Date!
    $includeConnectivityEvents: Boolean = false
    $connectivityEventsStartDate: Date!
    $connectivityEventsEndDate: Date!
    $includeAirFanEvents: Boolean = false
    $airFanEventsStartDate: Date!
    $airFanEventsEndDate: Date!
    $airFaultsStartDate: Date!
    $airFaultsEndDate: Date!
    $includeAirFaults: Boolean = false
  ) {
    device(accountId: $accountId, serialNumber: $serialNumber) {
      serialNumber
      type
      state {
        state
        timestamp
        __typename
      }
      connectivity {
        timestamp
        connected
        __typename
      }
      firmwareVersion {
        value
        timestamp
        pendingValue
        pendingTimestamp
        __typename
      }
      fullLocationPath {
        id
        name
        __typename
      }
      firstConnection
      nickname
      installationDate
      shadowReported
      account {
        id
        name
        __typename
      }
      ... on DeviceAIR175 {
        lastServiceDate
        settings {
          downlightBrightness {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          __typename
        }
        airStatus {
          bulbStatus {
            timestamp
            value
            pendingValue
            pendingTimestamp
            __typename
          }
          fanStatus {
            timestamp
            value
            pendingValue
            pendingTimestamp
            __typename
          }
          door1Closed {
            timestamp
            value
            pendingValue
            pendingTimestamp
            __typename
          }
          activeFaults {
            code {
              timestamp
              value
              pendingValue
              pendingTimestamp
              __typename
            }
            description {
              timestamp
              value
              pendingValue
              pendingTimestamp
              __typename
            }
            __typename
          }
          __typename
        }
        bulbStats {
          bulbBurnTimeSeconds {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          maxBulbBurnTimeSeconds {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          bulbCycles {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          maxBulbCycles {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          remainingBulbLifePercentage
          __typename
        }
        estimatedReplacementKitDueDate
        __typename
      }
      ... on DeviceAIR20 {
        lastServiceDate
        downlightEnabled {
          value
          timestamp
          pendingValue
          pendingTimestamp
          __typename
        }
        settings {
          downlightBrightness {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          treatmentMode {
            pendingTimestamp
            pendingValue
            timestamp
            value
            __typename
          }
          __typename
        }
        airStatus {
          bulbStatus {
            timestamp
            value
            pendingValue
            pendingTimestamp
            __typename
          }
          fanStatus {
            timestamp
            value
            pendingValue
            pendingTimestamp
            __typename
          }
          activeFaults {
            code {
              timestamp
              value
              pendingValue
              pendingTimestamp
              __typename
            }
            description {
              timestamp
              value
              pendingValue
              pendingTimestamp
              __typename
            }
            __typename
          }
          __typename
        }
        bulbStats {
          bulbBurnTimeSeconds {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          maxBulbBurnTimeSeconds {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          bulbCycles {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          maxBulbCycles {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          remainingBulbLifePercentage
          __typename
        }
        estimatedReplacementKitDueDate
        __typename
      }
      ... on DeviceUVA20 {
        settings {
          idle_timer_ms {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          clean_timer_ms {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          uv_on_timer_ms {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          __typename
        }
        bulbStats {
          bulbBurnTimeSeconds {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          maxBulbBurnTimeSeconds {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          bulbCycles {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          maxBulbCycles {
            value
            timestamp
            pendingValue
            pendingTimestamp
            __typename
          }
          remainingBulbLifePercentage
          __typename
        }
        __typename
      }
      ... on DeviceUVA20 {
        cleaningCycles(startDate: $cleaningCyclesStartDate, endDate: $cleaningCyclesEndDate)
          @include(if: $includeCleaningCycles) {
          _id
          utcStartTime
          utcEndTime
          interrupted
          cycleLengthSeconds
          pauseReason
          uvLightTimeSeconds
          cyclePercentCompleted
          __typename
        }
        motionEvents(startDate: $motionEventsStartDate, endDate: $motionEventsEndDate)
          @include(if: $includeMotionEvents) {
          _id
          utcStartTime
          utcEndTime
          is_pause_event
          isPauseEvent
          __typename
        }
        __typename
      }
      otaEvents(startDate: $otaEventsStartDate, endDate: $otaEventsEndDate)
        @include(if: $includeOtaEvents) {
        _id
        utcStartTime
        utcEndTime
        error
        errorMessage
        firmwareVersion
        newFirmwareVersion
        prevFirmwareVersion
        __typename
      }
      infoEvents(startDate: $infoEventsStartDate, endDate: $infoEventsEndDate)
        @include(if: $includeInfoEvents) {
        _id
        event
        eventData
        firmwareVersion
        timestamp
        __typename
      }
      errorEvents(startDate: $errorEventsStartDate, endDate: $errorEventsEndDate)
        @include(if: $includeErrorEvents) {
        _id
        timestamp
        errorState
        firmwareVersion
        previousState
        __typename
      }
      connectivityEvents(
        startDate: $connectivityEventsStartDate
        endDate: $connectivityEventsEndDate
      ) @include(if: $includeConnectivityEvents) {
        _id
        timestamp
        status
        versionNumber
        disconnectReason
        clientInitiatedDisconnect
        principalIdentifier
        sessionIdentifier
        ipAddress
        __typename
      }
      ... on DeviceAIR175 {
        airFanEvents(startDate: $airFanEventsStartDate, endDate: $airFanEventsEndDate)
          @include(if: $includeAirFanEvents) {
          id
          model
          serialNumber
          firmwareVersion
          timestamp
          fanStatus
          currentFilterOnTime
          systemFilterOnTime
          __typename
        }
        airFaultEvents(startDate: $airFaultsStartDate, endDate: $airFaultsEndDate)
          @include(if: $includeAirFaults) {
          id
          firmwareVersion
          timestamp
          faultId
          faultName
          faultDescription
          activeFaultsBitmap
          __typename
        }
        __typename
      }
      ... on DeviceAIR20 {
        airFanEvents(startDate: $airFanEventsStartDate, endDate: $airFanEventsEndDate)
          @include(if: $includeAirFanEvents) {
          id
          model
          serialNumber
          firmwareVersion
          timestamp
          fanStatus
          currentFilterOnTime
          systemFilterOnTime
          __typename
        }
        airFaultEvents(startDate: $airFaultsStartDate, endDate: $airFaultsEndDate)
          @include(if: $includeAirFaults) {
          id
          firmwareVersion
          timestamp
          faultId
          faultName
          faultDescription
          activeFaultsBitmap
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

interface DeviceDetailsPageProps {
  selectedOrg: SelectedOrgType;
}
export default function DeviceDetailsPage({ selectedOrg }: DeviceDetailsPageProps) {
  const { showBoundary: showErrorBoundary } = useErrorBoundary();

  const navigate = useNavigate();
  const params = useParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { dispatchModal } = useModal();
  const [copied, copyText] = useCopy();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [cycleStartDate, cycleEndDate] = useMemo(() => {
    const currDateTime = moment();
    const endDate = currDateTime.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    const oneDayBeforeEndDate = currDateTime.subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    return [oneDayBeforeEndDate, endDate];
  }, []);

  const {
    loading: isLoading,
    error: queryError,
    data: deviceDetails,
    variables: deviceDetailsVar,
    refetch,
    startPolling,
    stopPolling,
  } = useQuery<DeviceQuery, DeviceQueryVariables>(GET_DEVICE_DETAILS, {
    variables: {
      accountId: selectedOrg?.id ?? '',
      serialNumber: params.device_sr ?? '',
      cleaningCyclesStartDate: cycleStartDate,
      cleaningCyclesEndDate: cycleEndDate,
      motionEventsStartDate: cycleStartDate,
      motionEventsEndDate: cycleEndDate,
      infoEventsStartDate: cycleStartDate,
      infoEventsEndDate: cycleEndDate,
      otaEventsStartDate: cycleStartDate,
      otaEventsEndDate: cycleEndDate,
      errorEventsStartDate: cycleStartDate,
      errorEventsEndDate: cycleEndDate,
      connectivityEventsStartDate: cycleStartDate,
      connectivityEventsEndDate: cycleEndDate,
      airFaultsStartDate: cycleStartDate,
      airFaultsEndDate: cycleEndDate,
      airFanEventsStartDate: cycleStartDate,
      airFanEventsEndDate: cycleEndDate,
    },
  });

  const [{ lastUpdated }, pollState, setPollState] = usePolling({
    startPolling,
    stopPolling,
  });

  if (queryError) {
    showErrorBoundary(queryError);
  }

  const refetchHandler = useCallback(
    async (newDeviceDetailsVars?: Partial<DeviceQueryVariables>) => {
      await refetch(Object.assign(deviceDetailsVar ?? {}, newDeviceDetailsVars));
      setPollState(POLL_STATES.LIVE); // set to live polling state
    },
    [deviceDetailsVar, refetch, setPollState]
  );

  const deviceSettingsOptions = useMemo(() => {
    const currentDeviceLocation = deviceDetails?.device?.fullLocationPath
      ? deviceDetails?.device?.fullLocationPath[deviceDetails?.device?.fullLocationPath.length - 1]
          ?.id
      : void 0;
    const optionsMap: Record<
      'edit' | 'unregister' | 'change-location' | 'recalibrate',
      { title: string; cb: () => void }
    > = {
      edit: {
        title: 'Edit Device',
        cb: () =>
          dispatchModal({
            type: ModalNotificationTypeEnum.DEVICE_SETTINGS_EDIT_DEVICE,
            modalProps: {
              serialNumber: deviceDetails?.device?.serialNumber,
              nickname: deviceDetails?.device?.nickname,
              installationDate: deviceDetails?.device?.installationDate,
              downlightEnabled: (deviceDetails?.device as DeviceAir20 | undefined)
                ?.downlightEnabled,
              type: deviceDetails?.device?.type,
              firmwareVersion: deviceDetails?.device?.firmwareVersion,
              onSuccess: refetchHandler,
            },
          }),
      },
      'change-location': {
        title: 'Change Location',
        cb: () =>
          dispatchModal({
            type: ModalNotificationTypeEnum.DEVICE_SETTINGS_CHANGE_LOCATION,
            modalProps: {
              serialNumber: deviceDetails?.device?.serialNumber,
              locationId: currentDeviceLocation,
              type: deviceDetails?.device?.type,
              onSuccess: refetchHandler,
            },
          }),
      },
      unregister: {
        title: 'Unregister Device',
        cb: () =>
          dispatchModal({
            type: ModalNotificationTypeEnum.DEVICE_SETTINGS_UNREGISTER_DEVICE,
            modalProps: {
              serialNumber: deviceDetails?.device?.serialNumber,
              type: deviceDetails?.device?.type,
              onSuccess: () => navigate(RoutePaths.PATH_DEVICES),
            },
          }),
      },
      recalibrate: {
        title: 'Recalibrate',
        cb: () =>
          dispatchModal({
            type: ModalNotificationTypeEnum.DEVICE_SETTINGS_RECALIBRATE_DEVICE,
            modalProps: {
              serialNumber: deviceDetails?.device?.serialNumber,
              type: deviceDetails?.device?.type,
              onSuccess: refetchHandler,
            },
          }),
      },
    };
    let options: { title: string; cb: () => void }[] = [];
    if ([DeviceType.Air175, DeviceType.Air20].some((t) => deviceDetails?.device?.type === t)) {
      options = [optionsMap['edit'], optionsMap['change-location'], optionsMap['unregister']];
    } else if ([DeviceType.Uva20].some((t) => deviceDetails?.device?.type === t)) {
      options = [
        optionsMap['edit'],
        optionsMap['recalibrate'],
        optionsMap['change-location'],
        optionsMap['unregister'],
      ];
    }
    return options;
  }, [deviceDetails, dispatchModal, refetchHandler, navigate]);

  const serialNumberDesc = (
    <Box
      display='flex'
      width='100%'
      alignItems={isMobile ? 'flex-start' : 'center'}
      flexDirection={isMobile ? 'column' : 'row'}
    >
      <Box display='flex' flexGrow='1' alignItems='center' gap={1}>
        <Typography variant='subtitle1'>{deviceDetails?.device?.serialNumber}</Typography>
        <Tooltip title={copied ? 'Copied' : 'Copy Serial Number'}>
          {copied ? (
            <IconButton onClick={(e) => e.preventDefault()}>
              <CheckIcon color='success' sx={{ fontSize: 16 }} />
            </IconButton>
          ) : (
            <IconButton onClick={(e) => copyText(e, deviceDetails?.device?.serialNumber as string)}>
              <CopyIcon sx={{ fontSize: 16 }} />
            </IconButton>
          )}
        </Tooltip>
        <Tooltip title='Settings'>
          <IconButton
            aria-label='device-settings-menu'
            id='device-settings-menu-trigger'
            aria-haspopup='true'
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <SettingsIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Tooltip>
        <Menu
          id='device-settings-menu'
          MenuListProps={{
            'aria-labelledby': 'device-settings-menu-trigger',
          }}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          {deviceSettingsOptions.map((option) => (
            <MenuItem
              key={option.title}
              onClick={() => {
                option.cb();
                setAnchorEl(null);
              }}
            >
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Typography variant='subtitle2'>Updated {moment(lastUpdated).fromNow()}</Typography>
    </Box>
  );

  return (
    <PageContainerFrame pageTitles={[deviceDetails?.device?.nickname ? deviceDetails?.device?.nickname : deviceDetails?.device?.serialNumber, 'Device Details']}>
      {isLoading ? (
        <CircularProgress sx={{ alignSelf: 'center', marginTop: 10 }} size={100} />
      ) : (
        <SettingsPanelFrame
          title={
            deviceDetails?.device?.nickname ? deviceDetails?.device?.nickname : 'Device Details'
          }
          description={serialNumberDesc}
        >
          <DeviceDetailsView device={deviceDetails?.device} refetch={refetchHandler} />
          <PollingSpeedDial pollState={pollState} setPollState={setPollState} />
        </SettingsPanelFrame>
      )}
    </PageContainerFrame>
  );
}
