import { useEffect, useState } from 'react';
import QuickMetricsCard from 'Components/MetricCards/QuickMetricsCard';
import { gaugeColors, metricCardIconColor } from 'Constants/OverviewConsts';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import RoutePaths from 'Constants/RoutePaths';
import { MetricReportTypeEnum } from 'Constants/FloorsViewEnums';
import MetricGauge from 'Components/MetricCards/ChartComponents/MetricGauge';
import type { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';

import type { GaugeConfigType, RangeConfigType } from 'Constants/OverViewTypes';
import { useTheme, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { cache } from 'Apollo/ApolloCache';
import { LOCATION_CORE_FRAGMENT } from 'fragments';
import { LocationCoreFragment } from '__generated__/graphql';

export type LocationHumidityCardContainerProps = {
  humidityDataToAverage?: (number | null | undefined)[];
  isLoading: boolean;
  startDate: string;
  endDate: string;
  selectedLocationID: string | undefined;
  selectedOrgID: string | undefined;
  gridConfig: GridConfigType;
  error?: boolean;
};

const TITLE = 'Relative Humidity';
const INFO_TEXT =
  'Relative humidity is a measure of how much moisture is in the air compared to how much it could hold at that temperature. It’s important for indoor air quality because it affects our comfort, breathing, and even the things inside our homes. Ideally, indoor humidity should be around 30-50%. When it’s too high, it can feel sticky, and mold or dust mites can grow more easily; when it’s too low, the air feels dry, which can irritate our skin and lungs. Keeping humidity balanced creates a healthier and more comfortable indoor environment.';

export default function LocationHumidityCardContainer({
  humidityDataToAverage,
  isLoading,
  startDate,
  endDate,
  selectedLocationID,
  gridConfig,
  error,
}: LocationHumidityCardContainerProps) {
  const theme = useTheme();
  const [gaugeConfig, setGaugeConfig] = useState<GaugeConfigType>();

  useEffect(() => {
    const report = humidityDataToAverage ?? [];
    const percentHumidity =
      Math.floor(report.reduce((total, value) => (value ? value + (total ?? 0) : total), 0) ?? 0) /
      report.length;
    const selectedToolTipText = `Relative Humidity: ${percentHumidity}%`;
    const mainGaugeLabel = `${percentHumidity}%`;
    const { red, yellow, green } = gaugeColors;

    const rangeConfig: RangeConfigType = [
      {
        defaultColor: red.default,
        selectedColor: red.selected,
        limit: 10,
        tooltip: {
          defaultText: 'poor humidity range',
          selectedText: selectedToolTipText,
        },
      },
      {
        defaultColor: yellow.default,
        selectedColor: yellow.selected,
        limit: 19,
        tooltip: {
          defaultText: 'medium humidity range',
          selectedText: selectedToolTipText,
        },
      },
      {
        defaultColor: green.default,
        selectedColor: green.selected,
        limit: 60,
        tooltip: {
          defaultText: 'ideal humidity range',
          selectedText: selectedToolTipText,
        },
      },
      {
        defaultColor: yellow.default,
        selectedColor: yellow.selected,
        limit: 70,
        tooltip: {
          defaultText: 'medium humidity range',
          selectedText: selectedToolTipText,
        },
      },
      {
        defaultColor: red.default,
        selectedColor: red.selected,
        limit: 80,
        tooltip: {
          defaultText: 'poor humidty range',
          selectedText: selectedToolTipText,
        },
      },
    ];

    setGaugeConfig({
      rangeConfig,
      mainGaugeLabel,
      value: isNaN(percentHumidity) ? null : percentHumidity,
      minValue: 0,
      maxValue: 100,
    });
  }, [humidityDataToAverage]);
  const selectedLocation = cache.readFragment<LocationCoreFragment>({
    id: `Location:${selectedLocationID}`,
    fragment: LOCATION_CORE_FRAGMENT
  });
  const numHoursDataRange = moment(endDate).diff(startDate, 'hour');
  return (
    <QuickMetricsCard
      isLoading={isLoading}
      icon={
        <WaterDropIcon sx={{ color: error ? theme.palette.error.main : metricCardIconColor }} />
      }
      title={TITLE}
      infoText={INFO_TEXT}
      link={`${RoutePaths.PATH_REPORTS}?locationID=${selectedLocationID}&selectedMetric=${MetricReportTypeEnum.HUMIDITY}`}
      gridConfig={gridConfig}
      error={error}
      showInfoInModal
    >
      {gaugeConfig ? <MetricGauge gaugeConfig={gaugeConfig} /> : null}
      {(!gaugeConfig ||
        (gaugeConfig && (gaugeConfig.value === null || gaugeConfig.value === undefined))) && (
        <>
          <Typography alignContent={'center'} color={'InactiveCaptionText'}>
            No data available for <b>{selectedLocation?.name}</b>
          </Typography>
          <Typography alignContent={'center'} fontStyle={'italic'} color={'InactiveCaptionText'}>
            in the last{' '}
            <b>
              {numHoursDataRange} {numHoursDataRange > 1 ? 'hours' : 'hour'}
            </b>
          </Typography>
        </>
      )}
    </QuickMetricsCard>
  );
}
