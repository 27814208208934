import { useAuthenticator } from '@aws-amplify/ui-react';
import { apolloClient } from 'Apollo/ApolloConfig';
import { cache } from 'Apollo/ApolloCache';
import { useSearchParams } from 'react-router-dom';

const clearApolloCache = () => {
  apolloClient.resetStore().then(() => {
    cache.reset();
  });
};

const clearLocalStorage = () => {
  localStorage.clear();
};

const reloadWindow = () => {
  window.location.reload();
};

export function useSignOut() {
  const { signOut } = useAuthenticator((context) => [context.route]);
  const [_, setSearchParams] = useSearchParams();

  const handleSignOut = () => {
    signOut();
    setSearchParams({}, { replace: true });
    clearLocalStorage();
    clearApolloCache();
    reloadWindow();
  };

  return handleSignOut;
}
