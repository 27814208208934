/* eslint-disable no-magic-numbers */
import { useLazyQuery } from '@apollo/client';
import {
  BinInterval,
  LocationArchetype,
  LocationCoreFragment,
  LocationMetadataFragment,
  OccupancyReportQuery,
  OccupancyReportQueryVariables,
} from '__generated__/graphql';
import _ from 'lodash';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';
import { OCCUPANCY_REPORT } from '../Occupancy.gql';
import { OccupancyPageProps, OccupancyView } from '../occupancy.types';
import { OccupancyContext } from './OccupancyContext';
import { LOCATION_CORE_FRAGMENT, LOCATION_METADATA_FRAGMENT } from 'fragments';
import useFetchLocationWithFragments from 'Hooks/useFetchLocationWithFragments';

export default function OccupancyContextProvider({
  selectedOrg,
  selectedLocation,
  children,
}: OccupancyPageProps & { children: ReactNode }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [locationId, setLocationId] = useState<string | null | undefined>(
    searchParams.get('location') ?? selectedLocation?.id ?? selectedOrg?.rootLocation?.id ?? ''
  );
  const [currentView, setCurrentView] = useState(OccupancyView.LOADING);
  const [recalibrating, setRecalibrating] = useState(false);

  const [cycleStartDate, cycleEndDate] = useMemo(() => {
    const startDateFromParams = searchParams.get('start-date') as string | undefined;
    const endDateFromParams = searchParams.get('end-date') as string | undefined;

    if (startDateFromParams && endDateFromParams) {
      return [startDateFromParams, endDateFromParams];
    }
    return getPastDateTimeRange(6, 'hours');
  }, [searchParams]);

  const currentInterval: BinInterval = useMemo(() => {
    const intervalFromParams = searchParams.get('interval') as BinInterval | undefined;
    if (!intervalFromParams) {
      return BinInterval.FifteenMinutes;
    }
    return intervalFromParams;
  }, [searchParams]);

  const [
    refetchLocationOccupancy,
    { data: occupancyReport, loading: occupancyReportLoading, error: occupancyError },
  ] = useLazyQuery<OccupancyReportQuery, OccupancyReportQueryVariables>(OCCUPANCY_REPORT, {
    variables: {
      input: {
        accountId: selectedOrg?.id ?? '',
        locationId: locationId ?? '',
        timeRange: {
          endDate: cycleEndDate,
          startDate: cycleStartDate,
        },
        co2BinPeriod: currentInterval,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const { data: locationWithMetadataResult, loading: locationWithMetadataLoading } =
    useFetchLocationWithFragments<LocationCoreFragment & LocationMetadataFragment>(
      locationId,
      LOCATION_CORE_FRAGMENT,
      LOCATION_METADATA_FRAGMENT
    );
  const locationWithMetadata = locationWithMetadataResult?.location;

  useEffect(() => {
    if (locationWithMetadataLoading) {
      // if location is loading
      setCurrentView(OccupancyView.LOADING);
      return;
    }

    if (!locationWithMetadata) return;

    /**
     * When location is invalid
     * Any location type other than room is invalid
     * clear all url search query params except location
     */
    if (locationWithMetadata.archetype !== LocationArchetype.Room) {
      setSearchParams({ location: locationWithMetadata.id }, { replace: true });
      setCurrentView(OccupancyView.INVALID_LOCATION);
      return;
    }

    if (locationWithMetadata?.archetype === LocationArchetype.Room) {
      refetchLocationOccupancy();
    }

    /**
     * When location needs calibration
     * it is missing any of airVolumeFt3, assumedAch and co2BaselinePPM in metadata
     * clear all url search query params except location
     */
    const locationMetadata = locationWithMetadata.metadata;
    if (
      !locationMetadata?.airVolumeFt3 ||
      !locationMetadata?.assumedAch ||
      !locationMetadata?.co2BaselinePPM
    ) {
      setSearchParams({ location: locationWithMetadata.id }, { replace: true });
      setCurrentView(OccupancyView.CALIBRATE);
      return;
    }
    setCurrentView(OccupancyView.OCCUPANCY_CHART);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationWithMetadata, locationWithMetadataLoading]);

  const filterHandler = useCallback(
    async (args: OccupancyReportQueryVariables) => {
      if (locationWithMetadata && locationWithMetadata.archetype === LocationArchetype.Room) {
        await refetchLocationOccupancy({ variables: args }); // this is triggering OPTIMIZE_LOCATION_OCCUPANCY as well don't know why, needs more debugging
      }
    },
    [refetchLocationOccupancy, locationWithMetadata]
  );
  const onLocationChange = useCallback(
    (newLocationId: string) => {
      setCurrentView(OccupancyView.LOADING);
      setLocationId(newLocationId);
      setSearchParams(
        (prev) => ({ ...Object.fromEntries(prev.entries()), location: newLocationId }),
        {
          replace: true,
        }
      );
    },
    [setSearchParams]
  );

  return (
    <OccupancyContext.Provider
      value={{
        view: currentView,
        currentInterval,
        filterHandler,
        locationId: locationId,
        onLocationChange,
        occupancyGraphLoading: occupancyReportLoading,
        occupancyGraphError: occupancyError,
        occupancyGraphData: occupancyReport,
        locationCalibrationDetailsForOccupancy: locationWithMetadata,
        isRecalibrating: recalibrating,
        triggerCalibrationOnCurrentLocation: () => {
          if (!locationWithMetadata?.id) return;
          setSearchParams({ location: locationWithMetadata.id }, { replace: true });
          setCurrentView(OccupancyView.CALIBRATE);
          setRecalibrating(true);
        },
        resetPage: () => {
          if (!locationWithMetadata?.id) return;
          setRecalibrating(false);
          setCurrentView(OccupancyView.OCCUPANCY_CHART);
          setSearchParams({ location: locationWithMetadata.id }, { replace: true });
        }
      }}
    >
      {children}
    </OccupancyContext.Provider>
  );
}

export function useOccupancyContext() {
  const ctx = useContext(OccupancyContext);
  if (!ctx) {
    throw new Error('useOccupancyContext must be used within OccupancyContextProvider');
  }
  return ctx;
}
