/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Fragment, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import {
  Air,
  AutoAwesome as AutoAwesomeIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Grain,
  Masks,
  PersonPinCircle,
  Science,
  TouchApp,
} from '@mui/icons-material';
import { currentUserDataVar, SelectedOrgType, selectedOrgVar } from 'Apollo/ApolloCache';
import ContinuumAppPageEnums from 'Constants/ContinuumAppPagesEnums';
import RoutePaths from 'Constants/RoutePaths';
import { DeviceType } from '__generated__/graphql';
import { NavLink } from 'react-router-dom';
import { canViewFeature, FeatureFlagFeatures } from 'Utils/FeatureFlags';

type AnalysisMenuItemConfigType = {
  primaryText: string;
  icon: JSX.Element;
  path?: string;
  handleClick?: () => void;
  pageType?: ContinuumAppPageEnums;
  disabled?: boolean;
  applicableDeviceTypes?: Array<DeviceType>;
  beta?: boolean;
};

type Props = {
  handleToggleSideBar: () => void;
  currentPage: ContinuumAppPageEnums;
  selectedOrg?: SelectedOrgType;
};

export default function AnalysisListItem({ handleToggleSideBar }: Props) {
  const currentUser = useReactiveVar(currentUserDataVar);
  const sideBarTextColor = 'white';
  const selectedOrg = useReactiveVar(selectedOrgVar);

  const analysisMenuItemsConfig: Array<AnalysisMenuItemConfigType> = [
    {
      primaryText: 'Air Treatment',
      icon: <Air />,
      path: RoutePaths.PATH_REPORTS_AIR,
      applicableDeviceTypes: [DeviceType.Air20, DeviceType.Air175],
    },
    {
      primaryText: 'Surface Treatment',
      icon: <TouchApp />,
      path: RoutePaths.PATH_REPORTS_SURFACE,
      applicableDeviceTypes: [DeviceType.Uva20],
    },
    {
      primaryText: 'Mold Spread',
      icon: <Grain />,
      beta: true,
      path: RoutePaths.PATH_MOLD,
      applicableDeviceTypes: [DeviceType.Air20],
    },
    {
      primaryText: 'Infection Spread',
      icon: <Masks />,
      beta: true,
      path: RoutePaths.PATH_INFECTION,
      applicableDeviceTypes: [DeviceType.Air20],
    },
    {
      primaryText: 'Occupancy',
      icon: <PersonPinCircle />,
      beta: true,
      disabled: !canViewFeature(FeatureFlagFeatures.OCCUPANCY_ANALYSIS, currentUser, selectedOrg),
      path: RoutePaths.PATH_OCCUPANCY,
      applicableDeviceTypes: [DeviceType.Air20],
    },
  ].filter((config) =>
    config.applicableDeviceTypes
      ? config.applicableDeviceTypes.some((i) => selectedOrg?.deviceTypes?.includes(i))
      : true
  );

  const [isAnalysisSubMenuOpen, setIsAnalysisSubMenuOpen] = useState<boolean>(() => {
    // TODO: check for any matching pages, then auto-open this menu, otherwise it is to be closed by default
    return false;
  });

  const AnalysisMenuItem = ({ config }: { config: AnalysisMenuItemConfigType }): JSX.Element => {
    const { primaryText, path, icon, disabled, beta } = config;
    return (
      <NavLink
        to={path ?? RoutePaths.PATH_PAGE_NOT_FOUND}
        style={{ color: 'inherit', textDecoration: 'none' }}
        end
      >
        {({ isActive }) => (
          <ListItem
            disablePadding
            onClick={(e) => {
              handleToggleSideBar();
              if (disabled) {
                e.preventDefault();
              }
            }}
            sx={{
              pl: 4,
              cursor: disabled ? 'not-allowed' : 'pointer',
            }}
          >
            <ListItemButton disabled={disabled} selected={isActive}>
              <ListItemIcon
                sx={{
                  color: sideBarTextColor,
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: sideBarTextColor,
                }}
                primary={primaryText}
                secondary={
                  disabled ? (
                    'Coming Soon'
                  ) : beta ? (
                    <Box component='span' display='flex' alignItems='center' gap={1}>
                      Beta <AutoAwesomeIcon fontSize='inherit' />
                    </Box>
                  ) : null
                }
                secondaryTypographyProps={{
                  color: 'inherit',
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </NavLink>
    );
  };

  if (analysisMenuItemsConfig.length === 0) {
    return null;
  }
  return (
    <Fragment>
      <ListItem disablePadding>
        <ListItemButton onClick={() => setIsAnalysisSubMenuOpen(!isAnalysisSubMenuOpen)}>
          <ListItemIcon
            sx={{
              color: sideBarTextColor,
            }}
          >
            {<Science />}
          </ListItemIcon>
          <ListItemText
            primary='Analysis'
            sx={{
              color: sideBarTextColor,
            }}
          />
          {isAnalysisSubMenuOpen ? (
            <ExpandLessIcon
              sx={{
                color: sideBarTextColor,
              }}
            />
          ) : (
            <ExpandMoreIcon
              sx={{
                color: sideBarTextColor,
              }}
            />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={isAnalysisSubMenuOpen}>
        <List disablePadding>
          {analysisMenuItemsConfig.map((config, index) => {
            return <AnalysisMenuItem key={index} config={config} />;
          })}
        </List>
      </Collapse>
    </Fragment>
  );
}
