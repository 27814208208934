import { useReactiveVar } from '@apollo/client';
import { appThemeVar } from 'Apollo/ApolloCache';
import Login from 'Components/Login';
import { Helmet } from 'react-helmet';
import { formatPageTitle } from 'Utils/formatPageTitle';

export default function LoginPage() {
  const theme = useReactiveVar(appThemeVar);
  const pageTitle = formatPageTitle(['Login']);

  return <>
    <Helmet defer={false}>
      <title>{pageTitle}</title>
    </Helmet>
    <Login theme={theme} />;
  </>;
}
