/* eslint-disable indent */
/* eslint-disable no-magic-numbers */
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { cache, selectedOrgVar } from 'Apollo/ApolloCache';
import QuickMetricsCard from 'Components/MetricCards/QuickMetricsCard';
import { gaugeColors, metricCardIconColor } from 'Constants/OverviewConsts';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import {
  convertTemperature,
  convertUnitOfMeasurePreferenceToTempScaleEnum,
} from 'Utils/conversionTools';
import type { GaugeConfigType, RangeConfigType } from 'Constants/OverViewTypes';
import { TempScaleEnum } from 'Constants/ConversionEnums';
import RoutePaths from 'Constants/RoutePaths';
import { MetricReportTypeEnum } from 'Constants/FloorsViewEnums';
import MetricGauge from 'Components/MetricCards/ChartComponents/MetricGauge';
import type { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';
import { useTheme, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { LOCATION_CORE_FRAGMENT } from 'fragments';
import { LocationCoreFragment } from '__generated__/graphql';

const { red, yellow, green, lightBlue, darkBlue } = gaugeColors;
const getMetricRangeConfig = (selectedToolTipText: string): RangeConfigType => {
  const MetricRangeConfig = [
    {
      defaultColor: darkBlue.default,
      selectedColor: darkBlue.selected,
      limit: 15,
      tooltip: {
        defaultText: 'low temp range',
        selectedText: selectedToolTipText,
      },
    },
    {
      defaultColor: lightBlue.default,
      selectedColor: lightBlue.selected,
      limit: 19,
      tooltip: {
        defaultText: 'medium temp range',
        selectedText: selectedToolTipText,
      },
    },
    {
      defaultColor: green.default,
      selectedColor: green.selected,
      limit: 24,
      tooltip: {
        defaultText: 'ideal temp range',
        selectedText: selectedToolTipText,
      },
    },
    {
      defaultColor: yellow.default,
      selectedColor: yellow.selected,
      limit: 29,
      tooltip: {
        defaultText: 'medium temp range',
        selectedText: selectedToolTipText,
      },
    },
    {
      defaultColor: red.default,
      selectedColor: red.selected,
      limit: 30,
      tooltip: {
        defaultText: 'high temp range',
        selectedText: selectedToolTipText,
      },
    },
  ];

  return MetricRangeConfig;
};

const getImperialRangeConfig = (selectedToolTipText: string): RangeConfigType => {
  const ImperialRangeConfig = [
    {
      defaultColor: darkBlue.default,
      selectedColor: darkBlue.selected,
      limit: 55,
      tooltip: {
        defaultText: 'low temp range',
        selectedText: selectedToolTipText,
      },
    },
    {
      defaultColor: lightBlue.default,
      selectedColor: lightBlue.selected,
      limit: 67,
      tooltip: {
        defaultText: 'medium temp range',
        selectedText: selectedToolTipText,
      },
    },
    {
      defaultColor: green.default,
      selectedColor: green.selected,
      limit: 76,
      tooltip: {
        defaultText: 'ideal temp range',
        selectedText: selectedToolTipText,
      },
    },
    {
      defaultColor: yellow.default,
      selectedColor: yellow.selected,
      limit: 86,
      tooltip: {
        defaultText: 'medium temp range',
        selectedText: selectedToolTipText,
      },
    },
    {
      defaultColor: red.default,
      selectedColor: red.selected,
      limit: 87,
      tooltip: {
        defaultText: 'high temp range',
        selectedText: selectedToolTipText,
      },
    },
  ];

  return ImperialRangeConfig;
};

export type LocationTemperatureCardContainerProps = {
  tempDataToAverage?: (number | null | undefined)[];
  isLoading: boolean;
  startDate: string;
  endDate: string;
  selectedLocationID: string | undefined;
  selectedOrgID: string | undefined;
  gridConfig: GridConfigType;
  error?: boolean;
};

const TITLE = 'Temperature';
const INFO_TEXT =
  'Temperature tells us how warm or cool the air feels. It’s important for indoor air quality because it affects how comfortable we feel and can impact our health. Keeping indoor temperatures in a comfortable range helps prevent issues like dry skin or breathing problems and keeps humidity levels balanced. This reduces the chances of mold or allergens growing indoors, creating a healthier space for everyone.';

export default function LocationTemperatureCardContainer({
  tempDataToAverage,
  isLoading,
  startDate,
  endDate,
  selectedLocationID,
  gridConfig,
  error,
}: LocationTemperatureCardContainerProps) {
  const theme = useTheme();

  const [gaugeConfig, setGaugeConfig] = useState<GaugeConfigType>();
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const unitOfMeasurePreference = selectedOrg?.preferences?.unit_of_measure;
  const tempScalePref = convertUnitOfMeasurePreferenceToTempScaleEnum(unitOfMeasurePreference);

  useEffect(() => {
    const report = tempDataToAverage ?? [];
    const avgTemp =
      (report.reduce((total, value) => (value ? value + (total ?? 0) : total), 0) ?? 0) /
      report.length;
    const temperature = report.length === 0 ? null : convertTemperature(avgTemp, tempScalePref);

    let selectedToolTipText;
    let mainGaugeLabel;
    let rangeConfig;
    let minValue;
    let maxValue;
    const precisionTempValue = temperature ? temperature.toPrecision(3) : '0';
    if (tempScalePref === TempScaleEnum.CELSIUS) {
      selectedToolTipText = `Temperature: ${precisionTempValue} C°`;
      mainGaugeLabel = `${precisionTempValue} C°`;
      rangeConfig = getMetricRangeConfig(selectedToolTipText);
      minValue = 10;
      maxValue = 34;
    } else {
      selectedToolTipText = `Temperature: ${precisionTempValue} °F`;
      mainGaugeLabel = `${precisionTempValue} °F`;
      rangeConfig = getImperialRangeConfig(selectedToolTipText);
      minValue = 40;
      maxValue = 100;
    }
    setGaugeConfig({
      rangeConfig,
      mainGaugeLabel,
      value: temperature,
      minValue,
      maxValue,
    });
  }, [tempDataToAverage, tempScalePref]);

  const selectedLocation = cache.readFragment<LocationCoreFragment>({
    id: `Location:${selectedLocationID}`,
    fragment: LOCATION_CORE_FRAGMENT
  });

  const numHoursDataRange = moment(endDate).diff(startDate, 'hour');
  return (
    <QuickMetricsCard
      isLoading={isLoading}
      icon={
        <ThermostatIcon sx={{ color: error ? theme.palette.error.main : metricCardIconColor }} />
      }
      title={TITLE}
      infoText={INFO_TEXT}
      link={`${RoutePaths.PATH_REPORTS}?locationID=${selectedLocationID}&selectedMetric=${MetricReportTypeEnum.TEMP}`}
      gridConfig={gridConfig}
      error={error}
      showInfoInModal
    >
      {gaugeConfig ? <MetricGauge gaugeConfig={gaugeConfig} /> : null}
      {(!gaugeConfig ||
        (gaugeConfig && (gaugeConfig.value === null || gaugeConfig.value === undefined))) && (
        <>
          <Typography alignContent={'center'} color={'InactiveCaptionText'}>
            No data available for <b>{selectedLocation?.name}</b>
          </Typography>
          <Typography alignContent={'center'} fontStyle={'italic'} color={'InactiveCaptionText'}>
            in the last{' '}
            <b>
              {numHoursDataRange} {numHoursDataRange > 1 ? 'hours' : 'hour'}
            </b>
          </Typography>
        </>
      )}
    </QuickMetricsCard>
  );
}
