/* eslint-disable no-magic-numbers */
import { useQuery, useReactiveVar } from '@apollo/client';
import { ElectricalServices as ElectricalServicesIcon } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import {
  GetLocationPowerConsumptionQuery,
  GetLocationPowerConsumptionQueryVariables,
  TimeInterval,
} from '__generated__/graphql';
import { selectedLocationVar } from 'Apollo/ApolloCache';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import MediumMetricsCard from 'Components/MetricCards/MediumMetricsCard';
import { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';
import { metricCardIconColor } from 'Constants/OverviewConsts';
import moment from 'moment';
import { useMemo } from 'react';
import { Bar, ChartProps } from 'react-chartjs-2';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';
import { POWER_CONSUMPTION_REPORT } from '../graphql';

Chart.register(annotationPlugin);

export type PowerConsumptionCardContainerProps = {
  selectedLocationID: string | undefined;
  selectedOrgID: string | undefined;
  gridConfig: GridConfigType;
};
const TITLE = 'Power Consumption';
const INFO_TEXT = 'Total Power Consumed in last 1 week';
export default function PowerConsumptionCardContainer({
  selectedLocationID,
  selectedOrgID,
  gridConfig,
}: PowerConsumptionCardContainerProps) {
  const theme = useTheme();

  const currentlySelectedLocation = useReactiveVar(selectedLocationVar);
  const [startDate, endDate] = useMemo(() => getPastDateTimeRange(7, 'days'), []);
  const {
    data,
    loading: isLoading,
    error: queryError,
  } = useQuery<GetLocationPowerConsumptionQuery, GetLocationPowerConsumptionQueryVariables>(
    POWER_CONSUMPTION_REPORT,
    {
      variables: {
        accountId: selectedOrgID ?? '',
        locationId: selectedLocationID ?? '',
        timeFrame: {
          startDate,
          endDate,
          timeInterval: TimeInterval.Daily,
        },
      },
    }
  );
  const { maxValue, maxValueIndex, cumulativeAmount } = useMemo(() => {
    const report = data?.report?.uvangel?.getLocationPowerConsumption;
    if (!report)
      return {
        maxValue: 0,
        maxValueIndex: 0,
        cumulativeAmount: 0,
      };
    let maxValue = -Infinity;
    let maxValueIndex = 0;
    let cumulativeAmount = 0;
    for (let i = 0; i < report.length; ++i) {
      const point = report[i];
      if (!point.y || !point.y.totalKWh) continue;

      if (maxValue < point.y.totalKWh) {
        maxValue = point.y.totalKWh;
        maxValueIndex = i;
      }
      cumulativeAmount += point.y.totalKWh;
    }
    return {
      maxValue,
      maxValueIndex,
      cumulativeAmount,
    };
  }, [data]);
  const chartOptions: ChartProps<'bar'>['options'] | null = useMemo(() => {
    if (!data?.report?.uvangel?.getLocationPowerConsumption) return null;

    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        bar: {
          borderRadius: 5,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          display: false,
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        annotation: {
          annotations: [
            {
              type: 'line',
              borderDash: [10],
              yMin: maxValue,
              yMax: maxValue,
              borderColor: '#51ba38',
              borderWidth: 2,
              label: {
                position: 'end',
                content: `${maxValue.toLocaleString()} kWh`,
                display: true,
                backgroundColor: 'transparent',
                color: '#51ba38',
                yAdjust: -12,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.formattedValue} kWh`,
          },
        },
      },
    };
  }, [data, maxValue]);
  const chartData: ChartProps<'bar'>['data'] | null = useMemo(() => {
    const report = data?.report?.uvangel?.getLocationPowerConsumption;
    if (!report) return null;
    const backgroundColors = report.map((_, index) =>
      index === maxValueIndex ? '#51ba38' : '#d7d7d7'
    );
    const labels = report.map((dataPoint) => {
      const timestamp = dataPoint?.x;
      return moment(timestamp).format('MM/DD');
    });
    return {
      labels,
      datasets: [
        {
          data: report.map((point) => Math.round(point.y?.totalKWh ?? 0)),
          backgroundColor: backgroundColors,
          hoverBackgroundColor: '#7f7f7f',
        },
      ],
    };
  }, [data, maxValueIndex]);
  const additionalText =
    cumulativeAmount > 0 ? `${Math.round(cumulativeAmount).toLocaleString()} kWh` : null;
  return (
    <MediumMetricsCard
      additionalText={additionalText}
      isLoading={isLoading}
      icon={
        <ElectricalServicesIcon
          sx={{ color: queryError ? theme.palette.error.main : metricCardIconColor }}
        />
      }
      title={TITLE}
      infoText={INFO_TEXT}
      gridConfig={gridConfig}
      error={!!queryError}
    >
      {chartData && chartOptions ? (
        <Bar plugins={[annotationPlugin]} data={chartData} options={chartOptions} />
      ) : (
        <>
          <Typography alignContent='center' color='InactiveCaptionText'>
            No data available for <b>{currentlySelectedLocation?.name}</b>
          </Typography>
          <Typography alignContent='center' fontStyle='italic' color='InactiveCaptionText'>
            in the last <b>14 days</b>
          </Typography>
        </>
      )}
    </MediumMetricsCard>
  );
}
