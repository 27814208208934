import { gql } from '@apollo/client';
import { LOCATION_CORE_FRAGMENT, LOCATION_METADATA_FRAGMENT } from 'fragments';

export const OCCUPANCY_REPORT = gql`
  query OccupancyReport($input: LocationOccupancyReportInput!) {
    report {
      uvangel {
        getLocationOccupancyForDuration(input: $input) {
          timestamp
          co2Ppm
          co2Emission
          peopleUnits
          roundedOccupancy
        }
      }
    }
  }
`;

export const LOCATION_CALIBRATION_DETAILS_FOR_OCCUPANCY = gql`
  ${LOCATION_CORE_FRAGMENT}
  ${LOCATION_METADATA_FRAGMENT}
  query LocationCalibrationDetailsForOccupancy($accountId: ID!, $locationId: ID!) {
    location(accountId: $accountId, locationId: $locationId) {
      ...LocationCore
      ...LocationMetadata
    }
  }
`;

export const OPTIMIZE_LOCATION_OCCUPANCY = gql`
  query OptimizeLocationOccupancy($input: OptimizeLocationOccupancyInput!) {
    report {
      uvangel {
        optimizeLocationOccupancy(input: $input) {
          optimizedCO2Baseline
          optimizedVentilationRate
        }
      }
    }
  }
`;

export const CALIBRATE_LOCATION = gql`
  ${LOCATION_CORE_FRAGMENT}
  ${LOCATION_METADATA_FRAGMENT}
  mutation CalibrateLocation(
    $accountId: ID!
    $locationId: ID!
    $airVolumeFt3: Float!
    $assumedAch: Float!
    $co2BaselinePPM: Int!
    $maximumOccupancy: Int!
  ) {
    updateLocation(
      accountId: $accountId
      locationId: $locationId
      metadata: {
        airVolumeFt3: $airVolumeFt3
        assumedAch: $assumedAch
        co2BaselinePPM: $co2BaselinePPM
        maximumOccupancy: $maximumOccupancy
      }
    ) {
      ...LocationCore
      ...LocationMetadata
    }
  }
`;

export const SET_BASELINE_LOCATION_METADATA = gql`
  ${LOCATION_CORE_FRAGMENT}
  ${LOCATION_METADATA_FRAGMENT}
  mutation SetBaselineLocationMetadata(
    $accountId: ID!
    $locationId: ID!
    $airVolumeFt3: Float!
    $maximumOccupancy: Int!
  ) {
    updateLocation(
      accountId: $accountId
      locationId: $locationId
      metadata: {
        airVolumeFt3: $airVolumeFt3
        maximumOccupancy: $maximumOccupancy
      }
    ) {
      ...LocationCore
      ...LocationMetadata
    }
  }
`;
