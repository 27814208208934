import { CircularProgress, Grid, Stack } from '@mui/material';
import { useOccupancyContext } from '../context';
import OccupancyFilters from './components/OccupancyFilters';
import OccupancyGraphView from './components/OccupancyGraphView';
export function OccupancyGraphAndFiltersView() {
  const occupancyContext = useOccupancyContext();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
        {occupancyContext.occupancyGraphLoading ? (
          <CircularProgress />
        ) : (
          <OccupancyGraphView selectedInterval={occupancyContext.currentInterval} />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
        <Stack width='100%' gap={2}>
          <OccupancyFilters timeInterval={occupancyContext.currentInterval} />
        </Stack>
      </Grid>
    </Grid>
  );
}
